@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  /* Estilos para dispositivos móveis */
  @media (max-width: 768px) {
    ::ng-deep nb-tabset ul.tabset {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      white-space: nowrap;
      padding: 0;
      margin: 0;
      list-style: none;
      justify-content: flex-start !important;
      /* Alinha as abas ao início */
      scroll-snap-type: x mandatory;
      /* Para rolagem suave entre as abas */
    }

    ::ng-deep nb-tabset ul.tabset li {
      flex: 0 0 auto;
      scroll-snap-align: start;
      /* Alinha as abas ao início da rolagem */
    }
  }

  /* Estilos para dispositivos maiores (computador) */
  @media (min-width: 769px) {
    ::ng-deep nb-tabset ul.tabset {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: visible;
      /* Alterado para visível */
      white-space: nowrap;
      padding: 0;
      margin: 0;
      list-style: none;
      justify-content: space-around;
      /* Comportamento padrão para desktop */
    }

    ::ng-deep nb-tabset ul.tabset li {
      flex: 0 0 auto;
    }
  }
}
